import { Container, Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Container sx={{pb:2}}>
        <Typography align="center"><small>&copy; MarriedByMissy 2022</small></Typography>
    </Container>
  );
};

export default Footer;
