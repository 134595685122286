import { Container, Divider, Typography, ImageList, ImageListItem } from "@mui/material";

function importAll(r) {
  return r.keys().map(r);
}

const Gallery = () => {
  const images = importAll(
    require.context("../assets/gallery", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h3" sx={{ fontFamily: "'Licorice', cursive", mb:4  }}>
        Gallery
      </Typography>
      <ImageList variant="masonry" cols={4} gap={8}>
        {images.map(img => (
          <ImageListItem key={img}>
            <img
              src={`${img}?w=150&auto=format`}
              loading="lazy"
              alt="Married by missy"
            />
          </ImageListItem>
          )
        )}
      </ImageList>
      <Divider sx={{ my: 8 }} />
    </Container>
  );
};

export default Gallery;
