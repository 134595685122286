import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import Header from "./components/Header";
import Intro from "./components/Intro";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Header />
        <Intro />
        <Gallery />
        <Testimonials />
        <Contact />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
