import {
  Box,
  Container,
  Divider,
  Typography
} from "@mui/material";
import BG1 from "../assets/bg1.jpeg";
import Logo from "../assets/logo.png";

const Header = () => {
  return (
    <Container sx={{mb:4}}>
      <Box sx={{ textAlign: "center", mb:4 }}>
        <Box
          component="img"
          src={Logo}
          alt="Married By Missy"
          sx={{
            p: 4,
            height: 300,
          }}
        />
        <Typography variant="h3" sx={{ fontFamily: "'Licorice', cursive" }}>
          Gold Coast - Wedding Celebrant
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${BG1})`,
          minHeight:{md:"50vh", xs:"25vh"},
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Divider sx={{my:8}}/>
    </Container>
  );
};

export default Header;
