import { Typography, Container, Box, Stack, Divider } from "@mui/material";
import missy from "../assets/missy.jpg";

const Intro = () => {
  return (
    <Container id="about">
      <Box>
        <Typography variant="h3" sx={{ fontFamily: "'Licorice', cursive" }}>
          A bit about me
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Typography>
            <p>
              Congratulations on your decision to marry and thank you for
              considering me to be part of your magical day.
            </p>
            <p>
              I love designing unique and unforgettable ceremonies that portray
              the unique love story of you and your partner. I love being a
              celebrant and having the opportunity to watch families come
              together as they celebrate the love they have for each other.
            </p>
            <p>
              Writing and performing ceremonial scripts is my passion, I write
              from the heart. I'm fun or serious depending on the requirement of
              the moment and I can deliver a ceremony in any style you prefer.
            </p>
            <p>
              There are many different ceremony options that I can guide you
              through to help you choose something that is reflective of your
              personality and style.
            </p>
          </Typography>
          <Box
            component="img"
            src={missy}
            sx={{ height: "100%", maxWidth: "250px" }}
          />
        </Stack>
      </Box>
      <Divider sx={{ my: 8 }} />
    </Container>
  );
};

export default Intro;
