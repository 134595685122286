import {
  Container,
  TextField,
  Grid,
  Typography,
  Box,
  Stack,
  Divider,
  Alert,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Send } from "@mui/icons-material";

const Contact = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const url = "https://us-central1-married-by-missy.cloudfunctions.net/sendEmail"
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(e) {
    setIsLoading(true)
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(e),
    }).then((resp) => {
      setIsLoading(false)
      setResponse(resp);
    });
  }

  return (
    <Box sx={{ backgroundColor: "#fefefe", py: 8, mt: 8 }}>
      <Container>
        <Stack
          direction={{
            xs:"column", md: "row"
          }}
          spacing={2}
          divider={<Divider orientation="vertical" variant="middle" flexItem />}
        >
          <Box sx={{maxWidth:{md:"60%", sm:"100%"}}}>
            <Typography
              variant="h3"
              sx={{ fontFamily: "'Licorice', cursive", mb: 4 }}
            >
              Let's chat
            </Typography>
            <Typography>
              I would love to chat with you about your big day at your earliest
              convenience. We need at least one month to submit the Notice of
              intended marriage so the earlier the better.
            </Typography>
          </Box>
          {
            response.status && !response.ok && <Box>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle> Oops, something went wrong
            </Alert>
          </Box>
          }
          {response?.ok ? (
            <Box sx={{width:"100%"}}>
              <Alert severity="success">
                <AlertTitle>Thank you</AlertTitle>I will be in touch as soon as
                possible
              </Alert>
            </Box>
          ) : (
            <Box
              onSubmit={handleSubmit(onSubmit)}
              component="form"
              sx={{width:"100%"}}
            >
              <Grid container autoComplete="off" spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="filled"
                        placeholder="Name"
                        helperText={errors?.name?.message}
                        error={!!errors.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "Invalid email",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="filled"
                        placeholder="Email"
                        helperText={errors?.email?.message}
                        error={!!errors.email}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Invalid phone number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="filled"
                        placeholder="Phone"
                        helperText={errors?.phone?.message}
                        error={!!errors.phone}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Date is required", valueAsDate: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="filled"
                        type="date"
                        placeholder="Date"
                        helperText={errors?.date?.message}
                        error={!!errors.date}
                        sx={{width:"100%"}}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} row={4}>
                  <Controller
                    name="body"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Message is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        rows={5}
                        variant="filled"
                        placeholder="How can I help?"
                        helperText={errors?.body?.message}
                        error={!!errors.body}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <LoadingButton type="submit" variant="contained" startIcon={<Send />} loading={isLoading}>
                  Send
                </LoadingButton>
              </Grid>
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Contact;
