import { Typography, Container, Stack, Card, CardContent } from "@mui/material";

const Testimonials = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h3" sx={{fontFamily: "'Licorice', cursive", mb:4 }}>
            Testimonials
          </Typography>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Card md={6} variant="outlined">
          <CardContent>
            <Typography sx={{ mb: 2 }}>
              We can not thank you enough for all that you have done for us. It
              was all I've ever dreamt of and I feel truly blessed to have you
              as a friend. I can not express the gratitude I have for you. You
              are truly an amazing woman and a wonderful celebrant. These
              memories are etched in my heart forever. Thank you &amp; Kelly so
              much.
            </Typography>
            <Typography><strong>Sam &amp; Steve</strong></Typography>
          </CardContent>
        </Card>
        <Card md={6} variant="outlined">
          <CardContent>
            <Typography sx={{ mb: 2 }}>
              Melissa truly outdid herself on the day, it was a magical,
              beautiful day!! From the moment I arrived at the beach it was
              everything I had imagined, professional, relaxed and friendly with
              perfect weather and organised and ran exactly how Melissa said it
              would. We had two ceremonies on the day, the first one was held at
              the beach and Face-timed for our distant Canadian relatives and
              the second ceremony later that night to surprise our friends.
              David and I had such a beautiful day, thank you so much Melissa!!
              xx
            </Typography>
            <Typography><strong>Justine &amp; David</strong></Typography>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};

export default Testimonials;
